import React, { useState } from 'react';
import { useLoadEffect } from 'sana/utils';
import PropTypes from 'prop-types';
import { Link } from 'sana/elements';
import { formatCategoryData } from '../../utils';
import styles from './../ProductSubcategory.module.scss';

const ContentWrapper = ({ data, subMenuList }) => {
  const [naviData, setNaviData] = useState([]);
  /* istanbul ignore next */
  useLoadEffect(() => {
    if (data.children) {
      const subList = formatCategoryData(data.children, subMenuList);
      setNaviData(subList);
    } else 
      setNaviData([]);
    
  }, [data.children, subMenuList]);

  return (
    <div className={styles.contentWrapper}>
      {data.link ? (
        <Link
          to={data.link.to}
          url={data.link.url}
          className={styles.title}
        >
          {data.title}
        </Link>
      ) : (
        <div className={styles.title}>{data.title}</div>
      )}
      {naviData.length > 0 && (
        <div className={styles.subcategoryWrapper}>
          <ul>
            {naviData.map(subItem => (
              <li key={subItem.id}>
                {subItem.link ? (
                  <Link to={subItem.link.to} url={subItem.link.url}>
                    {subItem.title}
                  </Link>
                ) : (
                  <Link className={styles.disbled}>{subItem.title}</Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

ContentWrapper.propTypes = {
  data: PropTypes.object.isRequired,
  subMenuList: PropTypes.array.isRequired,
};

export default React.memo(ContentWrapper);
