import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLoadEffect } from 'sana/utils';
import Image from './image/Image';
import ContentWrapper from './content/ContentWrapper';
import { requestNavigationData } from '../behavior/actions';
import styles from './ProductSubcategory.module.scss';

const ProductSubcategory = ({ model, id: contentBlockId, isDesignerMode }) => {
  if (!model) 
    return null;
  const dispatch = useDispatch();
  const { navigationData } = useSelector(state => state);
  const { menuId, categoryImage, subMenuList } = model;

  useLoadEffect(() => {
    dispatch(requestNavigationData(menuId, contentBlockId));
  }, [menuId]);

  return (
    <div
      className={classNames(styles.productCategoryWrapper, styles.squareStyle, isDesignerMode && styles.designerMode)}
    >
      {navigationData && navigationData[contentBlockId] ? (
        <>
          <Image
            imagePath={
              categoryImage
                ? categoryImage
                : navigationData[contentBlockId].image.path
            }
            imageAltText={navigationData[contentBlockId].title}
            data={navigationData[contentBlockId]}
            imageScalingOption={model.imageScalingOption}
          />
          <ContentWrapper
            data={navigationData[contentBlockId]}
            subMenuList={subMenuList}
          />
        </>
      ) : (
        <div className={styles.placeholder} />
      )}
    </div>
  );
};

ProductSubcategory.propTypes = {
  model: PropTypes.object,
  id: PropTypes.string,
  isDesignerMode:PropTypes.bool,
};

export default React.memo(ProductSubcategory);
