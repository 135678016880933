import React from 'react';
import PropTypes from 'prop-types';
import { LazyImage, Link } from 'sana/elements';
import styles from './../ProductSubcategory.module.scss';
import classNames from 'classnames';
import { getImageScalingClass } from '../../utils/helpers';

const Image = ({ imagePath, imageAltText, data ,imageScalingOption }) => {
  const ImageProps = {
    src: imagePath,
    alt: `Product category - ${imageAltText}`,
    draggable: 'false',
    visibleByDefault: true,
    className: styles.image,
  };

  return (
    <div className={classNames(styles.imageWrapper, styles[getImageScalingClass(imageScalingOption)])}>
      {data.link ? (
        <Link to={data.link.to} url={data.link.url}>
          <LazyImage {...ImageProps} />
        </Link>
      ) : (
        <LazyImage {...ImageProps} />
      )}
    </div>
  );
};

Image.propTypes = {
  imagePath: PropTypes.string,
  imageAltText: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  imageScalingOption:PropTypes.number.isRequired,
};

export default React.memo(Image);
