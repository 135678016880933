import { ofType } from 'redux-observable';
import { map, pluck, mergeMap } from 'rxjs/operators';
import { NAVIGATION_DATA_REQUESTED, navigationDataReceived } from './actions';
import { navigationQuery } from './queries';

const epic = (action$, _state$, { api }) =>
  action$.pipe(
    ofType(NAVIGATION_DATA_REQUESTED),
    mergeMap(({ payload: { navId, contentBlockId } }) => {
      const variables = {
        id: navId,
      };
      return api.graphApi(navigationQuery, variables).pipe(
        pluck('navigation', 'byId'),
        map(navigationData => {
          return navigationDataReceived(navigationData, contentBlockId);
        }),
      );
    }),
  );

export default epic;
