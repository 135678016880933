import { NAVIGATION_DATA_RECEIVED } from './actions';

const initialState = {
  navigationData: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case NAVIGATION_DATA_RECEIVED:
      const { navigationData, contentBlockId } = payload;
      return {
        navigationData: {
          ...state.navigationData,
          [contentBlockId]: navigationData,
        },
      };
    default:
      return state;
  }
};

export default reducer;
